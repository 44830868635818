import React from "react";
import Layout from "../Layout";

function About() {
  return (
    <div className="min-h-screen bg-gray-50 py-10 px-4">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-4xl font-bold text-gray-900 mb-8 text-center">
          About Us
        </h1>
        <p className="text-lg text-gray-700 mb-6">
          {" "}
          <span className="font-semibold">Shri ji Tution</span>, Shree Ji
          Tuition offers expert tutoring for students from LKG to 12th grade
          across CBSE, ICSE, and UP Board. We provide personalized, one-on-one
          sessions focusing on individual student needs. Our experienced tutors
          cover all subjects, ensuring comprehensive understanding and academic
          excellence. For inquiries, call 8009657788 and give your child the
          best educational support.
        </p>
        <span>
          श्री जी ट्यूशन L.K.G से 12th कक्षा तक के छात्रों के लिए CBSE ,ICSC ,
          UP बोर्ड में विशेषज्ञ ट्यूशन प्रदान करता है। हम प्रत्येक छात्र की
          आवश्यकताओं पर ध्यान केंद्रित करते हुए व्यक्तिगत, एक-से-एक सत्र प्रदान
          करते हैं। हमारे अनुभवी शिक्षक सभी विषयों को कवर करते हैं, जिससे व्यापक
          समझ और शैक्षणिक उत्कृष्टता सुनिश्चित होती है। जानकारी के लिए,
          8009657788 पर कॉल करें और अपने बच्चे को सर्वश्रेष्ठ शैक्षिक सहायता
          प्रदान करें।
        </span>
        <br />
        <br />
        <h2 className="text-3xl font-semibold text-gray-800 mb-4">
          Why Choose us
        </h2>
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Hands-On Learning with specific Example.</li>
          <li>Expert Teacher For All Boards.</li>
          <li>
            Comprehensive Curriculum covering from basics to advanced topics.
          </li>
          <li>Tailored Support with one-on-one coaching.</li>
        </ul>
        <h2 className="text-3xl font-semibold text-gray-800 mt-8 mb-4">
          Weekly and Monthly Test
        </h2>
        <ul className="list-disc pl-6 text-gray-700 space-y-2">
          <li>Weekly Test</li>
          <li>Monthly Test</li>
          <li>Career Guidance</li>
        </ul>
        <br />
        <h2 className="text-3xl font-semibold text-gray-800 mb-4">
          Get In Touch
        </h2>
        <span className="text-2xl">
          <p>📱Phone Number +918009657788 </p>
          <p>✉️ Email :- shreejihometuton@gmail.com</p>
          <p>Website:- www.shreejituition.com</p>
        </span>
      </div>
    </div>
  );
}

export default About;
