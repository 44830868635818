import React from "react";
import { Link, BrowserRouter, Route, Routes } from "react-router-dom";
import logo from "../../Image/logo.jpeg";

function Header() {
  return (
    <>
      <div className="bg-red-500 p-2 h-48 shadow-lg">
        <div className="container mx-auto flex justify-between items-center">
          <div className="h-28 w-36 ml-10">
            <img src={logo} alt="" />
          </div>{" "}
          <div className="text-yellow-400 text-5xl font-bold mr-28">
            Shree Ji Tuition
          </div>
          <nav>
            <ul className="flex space-x-6 text-black text-2xl font-bold">
              <li>
                <Link to={"/"}> Home </Link>
              </li>
              <li>
                <Link to={"/About"}> About </Link>
              </li>
              <li>
                <Link to={"/Courses"}> Courses </Link>
              </li>
              <li>
                <Link to={"/Faculty"}> Faculty </Link>
              </li>
              <li>
                <Link to={"/Gallery"}> Gallery </Link>
              </li>
              <li>
                <Link to={"/Contact"}> Contact </Link>
              </li>
            </ul>
          </nav>
        </div>

        <div className="container mx-auto text-center text-white">
          <h1 className=" text-indigo-700 text-2xl md:text-3xl font-bold mb-4">
            Transform Your Life with Coaching
            <p className="text-black  text-xl w-fit">
              New Batches Starting Soon....
            </p>
          </h1>
        </div>
      </div>
    </>
  );
}
export default Header;
