import "./App.css";
//import Layout from "./Layout";
import Header from "./Shared/Header";
import Footer from "./Shared/Footer";
import Faculty from "./Faculty";
import Gallery from "./Gallery";
import Contact from "./Contact";
import About from "./About";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import Courses from "./Courses";
import Home from "./Home";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Courses" element={<Courses />} />
        <Route path="/Faculty" element={<Faculty />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
